import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable, catchError, delay, finalize, fromEvent, map, mergeMap, of, take, tap } from 'rxjs';

import { Status } from '../../../enums/status.enum';
import { WindowService } from '../../../services/window.service';
import { IFRAME_LOGOUT_TIME, TOKEN_IDP } from './profile.constant';
import { Profile, Token } from './profile.interface';

/**
 * Декоратор Injectable используется для указания, что сервис ProfileService является инъекцией и должен быть предоставлен в корневом уровне приложения.
 */
@Injectable({ providedIn: 'root' })
export class ProfileService {
  /**
   * Устанавливает документ в качестве только для чтения
   * @type {DOCUMENT}
   */
  readonly #windowService = inject(WindowService);

  /**
   * Устанавливает документ в качестве только для чтения
   * @type {DOCUMENT}
   */
  readonly #document = inject(DOCUMENT);

  /**
   * Внедряет зависимость HttpClient в переменную httpClient.
   * @param {HttpClient} inject - Объект HttpClient для внедрения
   * @type {HttpClient}
   */
  readonly #httpClient = inject(HttpClient);

  /**
   * Создает сигнал с начальным статусом UNINITIALIZED.
   *
   * @type {Signal<Status>}
   */
  loadingStatus = signal<Status>(Status.UNINITIALIZED);

  /**
   * Создает сигнал, который может содержать профиль или null.
   *
   * @param {getProfile | null} initialValue - Начальное значение сигнала
   * @returns {Signal<getProfile | null>} - Возвращает сигнал с типом Profile или null
   */
  profile = signal<Profile | null>(null);

  /**
   * Получить пользователя.
   * @returns {Observable<getProfile | null>} Observable, который возвращает профиль пользователя или null в случае ошибки.
   */
  getProfile(): Observable<Profile | null> {
    this.loadingStatus.set(Status.LOADING);
    return this.#httpClient.get<Profile>('/auth/user').pipe(
      tap((user) => this.profile.set(user)),
      catchError((error) => {
        console.error('getUser', error);
        return of(null);
      }),
      finalize(() => this.loadingStatus.set(Status.LOADED)),
    );
  }

  /**
   * Метод для выхода из системы.
   * Создает iframe для отправки запроса на выход из системы через OAuth.
   * @returns {void}
   */
  logout(): void {
    this.getTokenIdp()
      .pipe(
        take(1),
        mergeMap((idp: keyof typeof TOKEN_IDP) => this.loadIframe(idp)),
        delay(IFRAME_LOGOUT_TIME),
      )
      .subscribe({
        next: () => this.#windowService.redirect('/oauth/logout', true),
        error: (error) => console.error(error),
      });
  }

  /**
   * Функция, которая создает Observable, который эмитирует событие загрузки iframe.
   *
   * @param {HTMLIFrameElement} iframe - HTML элемент iframe, для которого нужно отслеживать событие загрузки
   * @returns {Observable<Event>} - Observable, который эмитирует событие загрузки iframe
   */
  onLoadEvent(iframe: HTMLIFrameElement): Observable<Event> {
    return fromEvent(iframe, 'load');
  }

  /**
   * Загружает iframe для указанного идентификатора поставщика токенов.
   *
   * @param {keyof typeof TOKEN_IDP} idp - Идентификатор поставщика токенов.
   * @returns {Observable<Event>} - Observable, который испускает событие загрузки iframe.
   */
  private loadIframe(idp: keyof typeof TOKEN_IDP): Observable<Event> {
    const iframe = document.createElement('iframe');
    iframe.id = 'logout-iframe';
    iframe.style.cssText = 'width: 1000px;height: 400px;position: fixed;z-index: 1000;z-index: 1000;top: 20px;left: 506px;';
    iframe.style.display = 'none';
    iframe.src = TOKEN_IDP[idp];
    this.#document.body.appendChild(iframe);

    return this.onLoadEvent(iframe);
  }

  /**
   * Получает токен IDP.
   * @returns {Observable<keyof typeof TOKEN_IDP>} - Observable, который возвращает ключ из перечисления TOKEN_IDP.
   */
  private getTokenIdp(): Observable<keyof typeof TOKEN_IDP> {
    return this.#httpClient.get<Token>('/oauth/token').pipe(map((token) => token.idp as keyof typeof TOKEN_IDP));
  }
}
